import { MetaBroker } from 'state/mock'
import { buildIncentiveData, openDetailsWithBroker } from 'state/slices/ui/form'
import InfoPoint from '../common/InfoPoint'
import { formatCurrency, formatPercentage, prettyTokenBal } from 'toolbox/format'
import { calcLendRate, calcUtil } from 'toolbox/calc'
import { SimpleMetaDisplay } from './SimpleMetaDisplay'
import { useAppSelector } from 'state/hooks'
import { selectIsBlocked, showBlock } from 'state/slices/ui/dash'
import { selectIncentivesToggleOn } from 'state/slices/app/toggles'
import { getPriceFromName, selectPricesFromBrokers } from 'state/slices/app/brokers'
import { Tooltip } from 'components/common/Tooltip'
import { RewardRate } from 'components/rewards/RewardRateDisplay'

export const COLLATERAL = 'Supply'
export const BORROW = 'Borrow'
export interface MarketsRowProps {
  b: MetaBroker
}

function MarketsRow({ b }: MarketsRowProps) {
  const isBlocked = useAppSelector(selectIsBlocked)

  const openDetails = () => (isBlocked ? showBlock() : openDetailsWithBroker(b))
  const token = b.tokenMeta
  const incentivesToggleOn = useAppSelector(selectIncentivesToggleOn)
  const prices = useAppSelector(selectPricesFromBrokers)
  const aptPrice = getPriceFromName('aptos', prices)

  if (!b || !token.logo) return null
  const utilization = calcUtil(b)
  const assetPrice = b.underlyingAsset.price

  const meta = {
    firstIcon: token.logo,
    value: token.ticker,
    onClickCallback: openDetails,
    noHover: true
  }

  const useIncentives =
    (incentivesToggleOn && b?.underlyingAsset.name === 'usdtlz') ||
    (incentivesToggleOn && b?.underlyingAsset.name === 'usdc')

  const borrowInterestRate = b.interestRate
  const stabilityFeeRate = 0.0015 // 0.15%
  const borrowAPR = borrowInterestRate + stabilityFeeRate
  // None of the stability fee is passed to the lender
  const supplyAPR = calcLendRate(borrowInterestRate, b.interestFeeRate, b.utilization)
  const totalLend = b.scaledAvailableLiquidityUnderlying + b.scaledTotalBorrowedUnderlying
  const totalBorrow = b.scaledTotalBorrowedUnderlying
  const totalLendValue = totalLend * assetPrice
  const totalBorrowValue = totalBorrow * assetPrice
  const totalAvailableValue = b.scaledAvailableLiquidityUnderlying * assetPrice

  const incentiveData = buildIncentiveData(b, aptPrice, useIncentives)
  const { supplyRewardRate, borrowRewardRate } = incentiveData

  const doDisplay = () => {
    return (
      <div className="market-row clickable" onClick={openDetails}>
        <div className="market-column">
          <SimpleMetaDisplay {...meta} />
        </div>
        <div className="market-column">
          <p className="point">{formatPercentage(utilization)}</p>
        </div>
        <div className="market-column vert">
          <p className="point">
            {' '}
            {useIncentives
              ? formatPercentage(supplyAPR + supplyRewardRate)
              : formatPercentage(supplyAPR)}
          </p>
          {!!incentivesToggleOn && useIncentives && (
            <Tooltip
              label=""
              value={`Supply ${b?.tokenMeta.ticker} on Superposition and earn ${formatPercentage(
                supplyRewardRate
              )} APR in APT rewards, in addition to the ${formatPercentage(
                supplyAPR
              )} APR in lending interest`}>
              <RewardRate rewardRate={supplyRewardRate} timeFrame="2h" baseRate={supplyAPR} />
            </Tooltip>
          )}
        </div>
        <div className="market-column vert">
          <p className="point">
            {' '}
            {useIncentives
              ? formatPercentage(borrowAPR - borrowRewardRate)
              : formatPercentage(borrowAPR)}
          </p>
          {!!incentivesToggleOn && useIncentives && (
            <Tooltip
              label=""
              value={`Borrow ${b?.tokenMeta.ticker} on Superposition and earn ${formatPercentage(
                borrowRewardRate
              )} APR in APT rewards, which can offset the ${formatPercentage(
                borrowAPR
              )} APR in borrowing interest`}>
              <RewardRate
                rewardRate={borrowRewardRate}
                timeFrame="2h"
                isBorrow
                baseRate={borrowAPR}
              />
            </Tooltip>
          )}
        </div>
        <div className="market-column">
          <InfoPoint
            value={prettyTokenBal(b.scaledAvailableLiquidityUnderlying)}
            sub={formatCurrency(totalAvailableValue)}
          />
        </div>
        <div className="market-column">
          <InfoPoint value={prettyTokenBal(totalBorrow)} sub={formatCurrency(totalBorrowValue)} />
        </div>
        <div className="market-column">
          <InfoPoint value={prettyTokenBal(totalLend)} sub={formatCurrency(totalLendValue)} />
        </div>
      </div>
    )
  }

  return <div className="market-columns">{doDisplay()}</div>
}

export default MarketsRow
