import { createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchPortfolios } from 'state/thunks/fetchPortfolios'
import { createSelector } from 'reselect'
import { isEmptyOrNil } from 'toolbox/account'
import { FormattedPositions, MetaBroker } from 'state/mock'
import { calcLendRate } from 'toolbox/calc'
import { types } from '@concordia/super-sdk'
import {
  getBrokerByLoanNoteName,
  getPriceFromName,
  selectBrokersLoaded,
  selectBrokersWithMeta,
  selectPricesFromBrokers
} from '../app/brokers'
import { Evaluation } from '@concordia/super-json-api-client'
import { buildIncentiveData } from '../ui/form' // Add this import

type State = Loadable<types.SPortfolio | null>
export const initialState = initLoadable<types.SPortfolio | null>(null)

const portfolio = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    resetPortfolio: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolios.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchPortfolios.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchPortfolios.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export const { resetPortfolio } = portfolio.actions
export default portfolio.reducer
export const selectPortfolio = (s: RootState) => s.user.portfolio.value
export const selectPortfolioBusy = (s: RootState) => s.user.portfolio.status === 'busy'
export const selectPortfolioLoaded = (s: RootState) => s.user.portfolio.loadedOnce
export const selectPortfolioErrored = (s: RootState) => s.user.portfolio.status === 'errored'

export const selectPositions = createSelector(
  [selectPortfolio, selectPortfolioLoaded],
  (portfolio, loaded) => {
    if (!loaded || !portfolio) {
      return null
    }
    return { collaterals: portfolio?.collaterals, liabilities: portfolio?.liabilities }
  }
)

export const selectFormattedPositions = createSelector([selectPositions], (positions) => {
  if (!positions) {
    return { collaterals: {}, liabilities: {} }
  }
  const c = positions?.collaterals
  const l = positions?.liabilities
  const collaterals: types.NameBalanceMap = {}
  const liabilities: types.NameBalanceMap = {}

  if (!isEmptyOrNil(c)) {
    c.forEach((c) => {
      collaterals[c.instrument.name] = c.scaledAmount
    })
  }
  if (!isEmptyOrNil(l)) {
    l.forEach((l) => {
      liabilities[l.instrument.name] = l.scaledAmount
    })
  }
  const formattedPositions: FormattedPositions = {
    collaterals,
    liabilities
  }
  return formattedPositions
})

export interface PositionSummary {
  exchangeRateBalance: number
  exchangeRateValue: number
  hasPosition
  borrowAPR: number
  supplyAPR: number
}

export const calcSupplyData = (
  positions: FormattedPositions,
  broker: MetaBroker,
  utilization: number
): PositionSummary => {
  const depNoteName = broker.depositNote.name
  const assetPrice = broker.underlyingAsset.price
  const userSupply = positions.collaterals[depNoteName] || 0
  const exchangeRateBalance = userSupply * broker.depositNoteExchangeRate || 0
  const exchangeRateValue = exchangeRateBalance * assetPrice
  const borrowInterestRate = broker.interestRate
  const stabilityFee = 0.0015 // 0.15%
  const borrowAPR = borrowInterestRate + stabilityFee
  // None of the stability fee is passed to the lender
  const supplyAPR = calcLendRate(borrowInterestRate, broker.interestFeeRate, broker.utilization)
  const hasSupply = exchangeRateBalance > 0.00009999

  return {
    exchangeRateBalance,
    exchangeRateValue,
    supplyAPR,
    borrowAPR,
    hasPosition: hasSupply
  }
}

export const calcBorrowData = (
  positions: FormattedPositions,
  broker: MetaBroker
): PositionSummary => {
  const loanNoteName = broker.loanNote.name
  const assetPrice = broker.underlyingAsset.price
  const userLiab = positions.liabilities[loanNoteName] || 0
  const exchangeRateBalance = userLiab * broker.loanNoteExchangeRate || 0
  const exchangeRateValue = exchangeRateBalance * assetPrice || 0
  //uses apy for convention
  const hasBorrow = exchangeRateBalance > 0.00009999

  const borrowInterestRate = broker.interestRate
  const stabilityFee = 0.0015 // 0.15%
  const borrowAPR = borrowInterestRate + stabilityFee

  return {
    exchangeRateBalance,
    exchangeRateValue,
    borrowAPR,
    supplyAPR: 0,
    hasPosition: hasBorrow
  }
}

export const selectTotalLiability = createSelector(
  [selectPortfolio, selectPortfolioLoaded, selectPricesFromBrokers],
  (portfolio, portfolioLoaded, prices) => {
    if (!portfolioLoaded) {
      return 0
    }
    const liabilities = portfolio?.liabilities
    let totalLiability = 0
    liabilities?.forEach((l) => {
      const price = prices[l.instrument.name]
      totalLiability += l.scaledAmount * price
    })
    return totalLiability ? totalLiability : 0
  }
)

export const selectTotalCollateral = createSelector(
  [selectPortfolio, selectPortfolioLoaded, selectPricesFromBrokers],
  (portfolio, portfolioLoaded, prices) => {
    if (!portfolioLoaded) {
      return 0
    }
    const collaterals = portfolio?.collaterals
    let totalCollateral = 0
    collaterals?.forEach((c) => {
      const price = prices[c.instrument.name]
      totalCollateral += c.scaledAmount * price
    })
    return totalCollateral
  }
)

export const selectMinimumRequiredEquity = createSelector(
  [selectPortfolio, selectPortfolioLoaded],
  (portfolio, portfolioLoaded) => {
    if (!portfolioLoaded) {
      return 0
    }
    return portfolio?.risk?.requiredEquity || 0
  }
)

export const selectFreeEquity = createSelector(
  [selectPortfolioLoaded, selectTotalCollateral, selectTotalLiability, selectMinimumRequiredEquity],
  (portfolioLoaded, collat, liab, min) => {
    if (!portfolioLoaded) {
      return 0
    }
    return collat - liab - min || 0
  }
)

export const selectEquity = createSelector(
  [selectPortfolioLoaded, selectTotalCollateral, selectTotalLiability],
  (portfolioLoaded, collat, liab) => {
    if (!portfolioLoaded) {
      return 0
    }
    return collat - liab || 0
  }
)

export const selectHealthFactor = createSelector(
  [selectEquity, selectMinimumRequiredEquity, selectPortfolioLoaded],
  (equity, minRequired, portfolioLoaded) => {
    if (!portfolioLoaded) {
      return 0
    }
    return equity / minRequired
  }
)

export const calcHealthFactor = (simRisk: Evaluation) => {
  if (!simRisk) {
    return 0
  }
  const equity = simRisk.total_collateral - simRisk.total_liability
  const minReq = simRisk.mm
  return equity / minReq
}

export const selectPositionByName = createSelector(
  [selectFormattedPositions],
  (positions) => (name: string) => {
    return positions.collaterals[name] || positions.liabilities[name] || 0
  }
)

export const selectMaxBorrows = createSelector(
  [selectPortfolioLoaded, selectPortfolio],
  (loaded, portfolio) => {
    if (!loaded || !portfolio) {
      return {}
    }
    return portfolio.maxBorrow
  }
)

export interface CalcedMaxBorrow {
  maxBorrowUnderlying: number
  maxBorrowValueUSD: number
}

export const selectMaxBorrowForAsset = createSelector(
  [
    selectPortfolioLoaded,
    selectBrokersLoaded,
    selectBrokersWithMeta,
    selectMaxBorrows,
    selectPricesFromBrokers,
    selectFormattedPositions,
    (state, noteName) => noteName
  ],
  (loaded, brokersLoaded, brokers, maxBorrows, prices, positions, noteName) => {
    if (!loaded || !brokersLoaded) {
      return { maxBorrowUnderlying: 0, maxBorrowValueUSD: 0 }
    }
    const noteMax = getMaxBorrowForLoanNote(noteName, maxBorrows)
    const broker = getBrokerByLoanNoteName(brokers, noteName)
    const underlyingName = broker?.underlyingAsset.name
    const price = prices[underlyingName]
    const position = positions?.liabilities[noteName] || 0
    const maxBorrowUnderlying = (noteMax - position) * broker?.loanNoteExchangeRate
    const maxBorrowValueUSD = maxBorrowUnderlying * price
    if (maxBorrowUnderlying < 0 || maxBorrowValueUSD < 0) {
      return { maxBorrowUnderlying: 0, maxBorrowValueUSD: 0 }
    }
    return { maxBorrowUnderlying, maxBorrowValueUSD }
  }
)

export const getMaxBorrowForLoanNote = (noteName: string, maxBorrows: types.SMaxBorrow) => {
  const mb = maxBorrows[noteName]
  if (!mb) {
    return 0
  }
  return Number(mb)
}

export const isGreenZone = (hf: number) => {
  return hf > 1.5
}

export const isYellowZone = (hf: number) => {
  return hf <= 1.5 && hf > 1.2
}

export const isRedZone = (hf: number) => {
  return hf <= 1.2
}

export const isUnhealthy = (hf: number) => {
  return hf <= 1
}

export const selectNetAPR = createSelector(
  [selectFormattedPositions, selectBrokersWithMeta, selectPricesFromBrokers],
  (positions, brokers, prices) => {
    if (!positions || !brokers || !prices) {
      return 0
    }

    let totalSupplyValue = 0
    let totalBorrowValue = 0
    let weightedSupplyAPR = 0
    let weightedBorrowAPR = 0

    const aptPrice = getPriceFromName('aptos', prices)

    Object.entries(positions.collaterals).forEach(([noteName, amount]) => {
      const broker = brokers.find((b) => b.depositNote.name === noteName)
      console.log('BROKER(SUPPLY): ', broker.tokenMeta.ticker)
      if (broker) {
        const value = amount * broker.depositNoteExchangeRate * prices[broker.underlyingAsset.name]
        console.log('POSITIONAL VALUE USD: ', value)
        totalSupplyValue += value
        console.log('TOTAL SUPPLY VALUE USD: ', totalSupplyValue)
        const baseSupplyAPR = calcLendRate(
          broker.interestRate,
          broker.interestFeeRate,
          broker.utilization
        )
        console.log('BASE SUPPLY APR: ', baseSupplyAPR)

        let totalSupplyAPR = baseSupplyAPR
        if (broker.underlyingAsset.name === 'usdtlz' || broker.underlyingAsset.name === 'usdc') {
          const incentiveData = buildIncentiveData(broker, aptPrice, true)
          totalSupplyAPR += incentiveData.supplyRewardRate
        }

        weightedSupplyAPR += value * totalSupplyAPR
        console.log('WEIGHTED SUPPLY APR (WITH INCENTIVES): ', weightedSupplyAPR)
      }
    })

    Object.entries(positions.liabilities).forEach(([noteName, amount]) => {
      const broker = brokers.find((b) => b.loanNote.name === noteName)
      console.log('BROKER(BORROW): ', broker.tokenMeta.ticker)
      if (broker) {
        const value = amount * broker.loanNoteExchangeRate * prices[broker.underlyingAsset.name]
        console.log('POSITIONAL VALUE USD: ', value)
        totalBorrowValue += value
        console.log('TOTAL BORROW VALUE USD: ', totalBorrowValue)
        const borrowInterestRate = broker.interestRate
        const stabilityFee = 0.0015 // 0.15%
        let totalBorrowAPR = borrowInterestRate + stabilityFee
        console.log('BASE BORROW APR: ', totalBorrowAPR)
        if (broker.underlyingAsset.name === 'usdtlz' || broker.underlyingAsset.name === 'usdc') {
          const incentiveData = buildIncentiveData(broker, aptPrice, true)
          totalBorrowAPR -= incentiveData.borrowRewardRate
        }

        weightedBorrowAPR += value * totalBorrowAPR
        console.log('WEIGHTED BORROW APR (WITH INCENTIVES): ', weightedBorrowAPR)
      }
    })

    const totalValue = totalSupplyValue - totalBorrowValue
    console.log('TOTAL NET VALUE USD (totalSupplyValue - totalBorrowValue): ', totalValue)
    if (totalValue === 0) {
      return 0
    }

    const avgSupplyAPR = weightedSupplyAPR / totalSupplyValue
    console.log('AVG SUPPLY APR: (WEIGHTED SUPPLY APR / totalSupplyValue) ', avgSupplyAPR)
    const avgBorrowAPR = weightedBorrowAPR / totalBorrowValue
    console.log('AVG BORROW APR: (WEIGHTED BORROW APR / totalBorrowValue) ', avgBorrowAPR)

    // Adjust the netAPR calculation
    const netAPR =
      (avgSupplyAPR * totalSupplyValue - avgBorrowAPR * totalBorrowValue) / Math.abs(totalValue)
    console.log(
      'NET APR: (AVG SUPPLY APR * totalSupplyValue - avgBorrowAPR * totalBorrowValue) / Math.abs(totalValue) ',
      netAPR
    )
    return netAPR
  }
)
