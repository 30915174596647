import { types } from '@concordia/super-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { dismissNotifyThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { MetaBroker } from 'state/mock'
import { SDK_CONTEXT } from 'state/context'
import { OpenMultiplyVaultArgs } from '@concordia/super-sdk/src/io'
import { fetchAppData, fetchUserVault } from 'state/fetch'
import { eMessage, scaleUp } from 'toolbox/format'
import { SuperpositionAptosSDK, MultiplySDK } from '@concordia/super-aptos-sdk'
import { PacketResponse } from '@concordia/super-json-api-client'
import { SignAndSubmitTransactionCallback } from './doMoneyGun'
import { InputTransactionData } from '@aptos-labs/wallet-adapter-core'
import {
  AptosConfig,
  Aptos,
  Network,
  Hex,
  InputViewFunctionData,
  MoveStructId,
  MoveFunctionId
} from '@aptos-labs/ts-sdk'
import { AccountArgs, WaitArgs } from './doTx'
import { OPEN_MULTIPLY_ACTION, setTxSuccessState } from 'state/slices/ui/transaction'
import { closeMultiplyForm, closeMultiReview } from 'state/slices/ui/form'
import { MULTIPLY_ADDRESS } from './doClosePosition'

export interface MultiTxPayload {
  network: string
  lendBroker: MetaBroker
  lendAmount: number
  borrowBroker: MetaBroker
  borrowAmount: number
  principalAmount: number
  flashLoanAmount: number
  user: string
  borrowCoinsPerLendCoin: number
  signAndSub: SignAndSubmitTransactionCallback
}

export async function getVaultAddress(
  aptos: Aptos,
  vaultOwner: string,
  lendCoinType: MoveStructId,
  borrowCoinType: MoveStructId
): Promise<string> {
  const payload: InputViewFunctionData = {
    function: `${MULTIPLY_ADDRESS}::multiply_public::get_vault_address`,
    functionArguments: [vaultOwner],
    typeArguments: [lendCoinType, borrowCoinType]
  }
  const [vaultAddress] = (await aptos.view({
    payload
  })) as [string]
  return vaultAddress
}

export const doMultiTx = createAsyncThunk(
  'multiTx',
  async (payload: MultiTxPayload): Promise<any> => {
    toastLoad('Building transaction...')

    const envNetwork = process.env.REACT_APP_APTOS_NETWORK_SHORT
    const useNetwork = envNetwork === 'mainnet' ? Network.MAINNET : Network.TESTNET
    const aptosConfig = new AptosConfig({
      network: useNetwork
    })
    const aptos = new Aptos(aptosConfig)
    const superSdk = SDK_CONTEXT.superSdk
    const rootAddr = process.env.REACT_APP_APTOS_ROOT_ADDRESS
    const superAptosSDK = new SuperpositionAptosSDK(rootAddr)
    const multiplySDK = new MultiplySDK(MULTIPLY_ADDRESS, rootAddr)

    if (!rootAddr || !superSdk || !envNetwork || !aptos || !superAptosSDK) {
      console.log('rootAddress: ', rootAddr)
      console.log('superSdk: ', superSdk)
      console.log('Env network: ', envNetwork)
      console.log('aptosSDK: ', aptos)
      console.log('SuperpositionAptosSDK: ', superAptosSDK)
      dismissNotifyThrow(
        'Missing required system configuration',
        'Check the console for more information'
      )
    }

    const {
      network,
      lendBroker,
      lendAmount,
      borrowBroker,
      borrowAmount,
      flashLoanAmount,
      user,
      borrowCoinsPerLendCoin,
      principalAmount
    } = payload
    if (
      !network ||
      !lendBroker ||
      !borrowBroker ||
      !user ||
      !borrowCoinsPerLendCoin ||
      !principalAmount
    ) {
      dismissNotifyThrow(
        'Missing arguments',
        'The transaction request is missing required arguments, please try again'
      )
    }

    let vaultAddress = ''

    const borrowCoinType = borrowBroker.underlyingAsset.networkAddress
    const lendCoinType = lendBroker.underlyingAsset.networkAddress

    try {
      toastLoad('Creating vault...')
      vaultAddress = await getVaultAddress(
        aptos,
        user,
        lendCoinType as MoveStructId,
        borrowCoinType as MoveStructId
      )
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Failed to create user vault', eMessage(e))
    }

    // scale up the principal and borrow amounts
    const borrowDecimals = borrowBroker.underlyingAsset.decimals
    // Pad the principal amount to the nearest whole number
    const scaledUpPrincipal = Math.ceil(scaleUp(principalAmount, borrowDecimals))
    // Pad the borrow amount to the nearest whole number
    const scaledUpBorrowAmount = Math.ceil(scaleUp(borrowAmount, borrowDecimals))

    const lendDecimals = lendBroker.underlyingAsset.decimals
    const exchangeFeeRate = 0.0002 // 0.02% or 2 bps is the fee rate for converting APT to sthAPT
    const exchangeFeeAmount = lendAmount * exchangeFeeRate
    const lendAmountMinusFee = lendAmount - exchangeFeeAmount
    // Round down the expected lend amount after conversion
    const scaledUpLendAmount = Math.floor(scaleUp(lendAmountMinusFee, lendDecimals))

    // Ensure that the principal plus the borrow amount will
    // trade to at least the lend amount

    const totalCollateralInBorrowCoins = scaledUpPrincipal + scaledUpBorrowAmount
    const totalCollateralInLendCoins = Math.floor(
      totalCollateralInBorrowCoins / borrowCoinsPerLendCoin
    )
    if (totalCollateralInLendCoins < scaledUpLendAmount) {
      dismissNotifyThrow(
        'Insufficient funds',
        `There was an error calculating the total collateral required to open the vault.`
      )
    }

    let currentPortfolio = {
      collaterals: [],
      liabilities: []
    }
    try {
      const vp = await superSdk.fetcher.getUserVault(
        user,
        lendBroker.underlyingAsset.name,
        borrowBroker.underlyingAsset.name
      )

      currentPortfolio = vp.vault_balance
      if (currentPortfolio.collaterals.length === 0) {
        // The vault portfolio has not been created yet
      } else if (currentPortfolio.collaterals.length === 1) {
        // The vault portfolio state is returning the network
        // address of each coin instead of the instrument ID
        currentPortfolio.collaterals[0].instrumentId = lendBroker.depositNote.name
      } else {
        dismissNotifyThrow('Invalid vault portfolio', currentPortfolio.toString())
      }
      if (currentPortfolio.liabilities.length === 0) {
        // The vault portfolio has not been created yet
      } else if (currentPortfolio.liabilities.length === 1) {
        currentPortfolio.liabilities[0].instrumentId = borrowBroker.loanNote.name
      } else {
        dismissNotifyThrow('Invalid vault portfolio', currentPortfolio.toString())
      }
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Failed to fetch vault portfolio', eMessage(e))
    }

    const accountArgs: AccountArgs = {
      accountAddress: payload.user
    }

    let txArgs: OpenMultiplyVaultArgs

    const stringBorrowAmount = Math.floor(scaledUpBorrowAmount).toString()
    const stringLendAmount = Math.floor(scaledUpLendAmount).toString()
    txArgs = {
      network,
      lendBrokerName: lendBroker.underlyingAsset.name,
      lendAmount: stringLendAmount,
      borrowBrokerName: borrowBroker.underlyingAsset.name,
      borrowAmount: stringBorrowAmount,
      user: vaultAddress,
      currentPortfolioState: currentPortfolio
    }

    async function checkRegisteredToken({
      address,
      broker
    }: {
      address: string
      broker: MetaBroker
    }) {
      const coinType = broker.underlyingAsset.networkAddress

      const resources = await aptos.getAccountResources(accountArgs)
      const hasCoinRegistered = resources.find((r) => r.type.includes(coinType))
      if (!hasCoinRegistered) {
        toastLoad('Registering token...')
        const registerTx: InputTransactionData = {
          sender: address,
          data: {
            function: '0x1::managed_coin::register',
            typeArguments: [coinType],
            functionArguments: []
          }
        }
        try {
          await payload.signAndSub(registerTx)
        } catch (e: any) {
          console.error(e)
          dismissNotifyThrow('Token Not Registered', eMessage(e))
        }
      }
    }

    async function fetchPacket({ txArgs }: { txArgs: types.OpenMultiplyVaultArgs }) {
      try {
        const packet = await superSdk.openMultiplyVault(txArgs)
        return packet
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow(`Multiply Packet Not Created`, eMessage(e))
      }
    }

    async function signAndSubmitter({
      packet,
      lendCoinType,
      borrowCoinType,
      flashLoanAmount,
      principalAmount
    }: {
      packet: PacketResponse
      lendCoinType: string
      borrowCoinType: string
      flashLoanAmount: number
      principalAmount: number
    }) {
      toastLoad('Signing transaction...')
      try {
        const packetHex = Hex.fromHexString(packet.packet)
        const ar = packetHex.toUint8Array()
        const ix = multiplySDK.openMultiplyVaultIx(
          ar,
          principalAmount,
          flashLoanAmount,
          lendCoinType,
          borrowCoinType
        )

        const v2Ix: InputTransactionData = {
          sender: payload.user,
          data: {
            function: ix.function as MoveFunctionId,
            typeArguments: ix.type_arguments,
            functionArguments: ix.arguments
          }
        }

        const hash = await payload.signAndSub(v2Ix)
        return hash
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow('Transaction Not Submitted', eMessage(e))
      }
    }

    //execute transaction steps
    await checkRegisteredToken({ address: payload.user, broker: lendBroker })
    await checkRegisteredToken({ address: payload.user, broker: borrowBroker })
    const packet = await fetchPacket({ txArgs })

    let hash

    const scaledUpFlashLoanAmount = Math.floor(scaleUp(flashLoanAmount, lendDecimals))

    // 0.3% or 30 bps is the fee rate for flash loans
    const flashLoanFeeRate = 0.003
    if (scaledUpFlashLoanAmount * (1 + flashLoanFeeRate) > scaledUpBorrowAmount) {
      dismissNotifyThrow(
        'Flash loan amount calculation error',
        'The flash loan amount calculation is incorrect'
      )
    }
    hash = await signAndSubmitter({
      packet,
      lendCoinType,
      borrowCoinType,
      flashLoanAmount: scaledUpFlashLoanAmount,
      principalAmount: scaledUpPrincipal
    })

    try {
      toastLoad('Waiting for transaction confirmation...')
      const waitArgs: WaitArgs = {
        transactionHash: hash?.hash,
        options: {
          checkSuccess: true
        }
      }
      const result = await aptos.waitForTransaction(waitArgs)
      console.log(`Multiply result`, result)
      toastSuccess('Multiply success')
      closeMultiplyForm()
      // avoid displaying the review next time the multiply form is opened
      closeMultiReview()
      setTxSuccessState({
        amount: txArgs.borrowAmount,
        ticker: borrowBroker.underlyingAsset.name,
        txHash: hash?.hash,
        action: OPEN_MULTIPLY_ACTION,
        decimals: borrowBroker.underlyingAsset.decimals
      })
      fetchUserVault(
        payload.user,
        lendBroker.underlyingAsset.name,
        borrowBroker.underlyingAsset.name
      )
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Multiply failed', eMessage(e))
    }
    fetchAppData()
  }
)
