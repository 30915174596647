import rewardSVG from 'assets/icons/rewards.svg'
import close from 'assets/icons/close.svg'

export function RewardBanner() {
  return (
    <div className="reward-banner">
      <div className="reward-banner__left">
        <img src={rewardSVG} alt="reward" />
        <div className="reward-banner__content">
          <h3>We reward APT weekly!</h3>
          <p>Earn APT rewards by maintaining open positions in either zUSDC or zUSDT</p>
        </div>
      </div>
      {/* <img src={close} alt="close" className="clickable" /> */}
    </div>
  )
}
