import { SFlashLoanStrategy } from '@concordia/super-sdk/src/io'
import { MetaBroker } from 'state/mock'
import { BORROW_TAB, REPAY_TAB, WITHDRAW_TAB } from 'state/slices/ui/form'
import { FormTab } from 'state/slices/ui/form'

/// Calculate the lending earning rate
//
// The borrow rate only applies to the total borrow amount which
// can only be a fraction of the total lending amount.
//
// Annual interest earning amount = borrowAmount * (borrowRate - interestFeeRate)
//
// This annual interest earning then is divided by the total lending amount
// to get the annual lending rate.
//
// Annual Lending Rate = Annual interest earning amount / totalLendingAmount
//
// Then we can simplify the formula to:
// Annual Lending Rate = borrowAmount * (borrowRate - interestFeeRate) / totalLendingAmount
// Annual Lending Rate = (borrowRate - interestFeeRate) * (borrowAmount / totalLendingAmount)
// Annual Lending Rate = (borrowRate - interestFeeRate) * utilization
// Annual Lending Rate = borrowRate * (1 - interestFeeRate) * utilization
//
// So to conclude: the lending rate is discounted by the interest fee rate
// and proportional the utilization rate
export const calcLendRate = (borrowRate: number, interestFeeRate: number, utilization: number) => {
  return borrowRate * (1 - interestFeeRate) * utilization
}

export const estimateNewAvailable = (b: MetaBroker, value: string, tab: FormTab) => {
  if (!b) return 0
  const val = parseFloat(value)
  let coins = val
  if (tab === WITHDRAW_TAB || tab === BORROW_TAB) {
    coins = val * -1
  }

  const available = b.scaledAvailableLiquidityUnderlying
  const nextAvailable = available + coins
  return nextAvailable
}

export const estimateNewBorrowed = (currentBorrowVal: number, value: string, tab: FormTab) => {
  const val = parseFloat(value)
  let coins = 0
  if (tab === BORROW_TAB) {
    coins = val
  }
  if (tab === REPAY_TAB) {
    coins = val * -1
  }

  const nextBorrowed = currentBorrowVal + coins
  return nextBorrowed
}

export const calcNewUtil = (borrowed: number, total: number) => {
  const util = borrowed / total
  return util > 0 ? util : 0
}

export const calcUtil = (b: MetaBroker) => {
  const avail = b.scaledAvailableLiquidityUnderlying
  const total = b.scaledTotalBorrowedUnderlying + avail
  const util = b.scaledTotalBorrowedUnderlying / total
  return util > 0 ? util : 0
}

export function countUserAddresses(data) {
  // Initialize an empty object to store counts of each userAddress
  const counts = {}

  // Iterate over each key in the input object
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      // Extract the userAddress from the current item
      const userAddress = data[key].userAddress

      // If the userAddress is already in the counts object, increment its count
      // Otherwise, initialize it with a count of 1
      counts[userAddress] = counts[userAddress] ? counts[userAddress] + 1 : 1
    }
  }

  return counts
}

export function calculateNetAPR(strategy: SFlashLoanStrategy, leverage?: number) {
  if (!strategy) return 0

  //setup
  const borrowAPR = strategy?.borrow_apr
  const spFee = strategy?.multiply_fee
  const useLeverage = leverage ? leverage : strategy?.max_leverage
  const incentivesAPR = 0
  const stabilityFee = strategy?.stability_fee
  const stakingAPR = strategy?.multiply_apr
  const supplyAPR = strategy?.lend_apr

  const earningAPRs = stakingAPR + supplyAPR + incentivesAPR
  const earningAPRsWithLeverage = earningAPRs * useLeverage
  const lessOneLeverage = useLeverage - 1
  const payingAPRs = borrowAPR + stabilityFee
  const negativeAPRWithLeverage = payingAPRs * lessOneLeverage
  const netAPR = earningAPRsWithLeverage - negativeAPRWithLeverage
  return netAPR
}

export function floorAndFixToEightDecimals(value) {
  let scaledValue = value * Math.pow(10, 8)

  scaledValue = Math.floor(scaledValue)

  return scaledValue / Math.pow(10, 8)
}
